import { Col } from '@amzn/stencil-react-components/layout';
import { Modal, ModalProps } from '@amzn/stencil-react-components/modal';
import { Text, H2 } from '@amzn/stencil-react-components/text';
import useArb from '../../hooks/useArb';
import { InputWrapper, Select } from '@amzn/stencil-react-components/form';
import { LANGUAGES, LANGUAGES_BY_COUNTRY } from '../../Utils/CountryConstant';
import { View } from '@amzn/stencil-react-components/layout';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { useState } from 'react';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { Row } from '@amzn/stencil-react-components/layout';

export default function LanguageModal({ close, ...props }: Omit<ModalProps, 'children'>) {
  const { t, locale, setLocale } = useArb('appStrings');
  const [countryCode] = useSessionStorage('countryCode', 'other', { parseJSON: false });
  const [language, setLanguage] = useState(LANGUAGES[locale]);

  const changeLanguage = () => {
    if(language?.shortName) {
        setLocale(language.shortName);
    }
    close();
  };

  return (
    <Modal close={close} {...props}>
      <Col backgroundColor="neutral0" gridGap="S500" padding="S500">
        <H2>{t('PHO-language-selection-label', 'Language selection')}</H2>
        <Text id="text-test-id">
          {t('PHO-language-selection-instruction', "Pick the language you'd like to take your pre-hire orientation in")}
        </Text>
        <InputWrapper labelText="Language selection" id="language-selector" aria-required={true}>
          {(inputProps: any) => (
            <Select
              dataTestId="language-selector"
              options={LANGUAGES_BY_COUNTRY[countryCode as keyof typeof LANGUAGES_BY_COUNTRY]}
              renderOption={(item) => (
                <View dataTestId={'language-selector-' + item.shortName}>{item.longName}</View>
              )}
              valueAccessor={(item) => item.shortName}
              onChange={(item) => {
                setLanguage(item);
              }}
              value={language}
              {...inputProps}
            />
          )}
        </InputWrapper>
        <Row style={{ justifyContent: 'flex-end' }}>
          <Button onClick={changeLanguage} variant={ButtonVariant.Primary}>
            {t('PHO-btn-confirm', 'Confirm')}
          </Button>
        </Row>
      </Col>
    </Modal>
  );
}
