import {Col} from "@amzn/stencil-react-components/layout";
import {Spinner} from "@amzn/stencil-react-components/spinner";


function FullPageCenteredSpinner( props) {
    return (
        <Col height="calc(100vh - 56px)" alignItems="center" justifyContent="center" textAlign="center" style={{zIndex:"9999"}} id='full-page-spinner'>
           <Spinner size={props.size} showText loadingText={props.message} />
        </Col>
    )
}

export default FullPageCenteredSpinner