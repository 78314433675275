import React, { FC, useEffect, useState } from 'react';
import { Col, View } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { IconCheckCircleFill, IconPadlock } from '@amzn/stencil-react-components/icons';
// import { useState } from "react";
import { ReactElement } from 'react';
import constants from '../../Utils/Constants';

interface ISideNavProps {
  modules: Array<IModule>;
  isMobile: boolean;
  activeMenu: IModule;
  activeSubMenu: IContent;
  hideSelection: Boolean;
}
interface IModule {
  moduleId: string;
  moduleName: string;
  description: string;
  resourceUrl: string;
  contents: Array<IContent>;
  module_completed: boolean;
  status: string;
}

interface IContent {
  answer: string;
  contentData: Array<string>;
  contentId: string;
  contentName: string;
  contentType: string;
  description: string;
  question_completed: boolean;
  status: string;
}

const SideNavBar: FC<ISideNavProps> = (props) => {
  const [activeMenu, setActiveMenu] = useState(props.activeMenu);
  const [activeSubMenu, setActiveSubMenu] = useState(props.activeSubMenu);

  useEffect(() => {
    if (props.activeMenu?.moduleId) {
      setActiveMenu(props.activeMenu);
      setActiveSubMenu(props.activeSubMenu);
    }
  }, [props.activeMenu, props.activeSubMenu]);

  const getModuleIcon = (status: string): ReactElement => {
    if (status === constants.PHO.STATUS.COMPLETED) {
      return <IconCheckCircleFill color="green70" />;
    }
    if (status === constants.PHO.STATUS.TODO) {
      return (
        <View color="neutral40">
          <IconPadlock />
        </View>
      );
    }
    return <></>;
  };

  // const isActive = (menu: IModule): boolean => {
  //     if (menu.status === 1 && !menu.sub_menu)
  //         return true;
  //     return false;
  // }

  const getMenuItem = (menu: IModule, index: number, isFeedback?: boolean): ReactElement => {
    return (
      <View
        key={index}
        style={
          activeMenu?.moduleId === menu.moduleId && !activeSubMenu && !props.hideSelection
            ? { background: '#F1F4F7' }
            : {}
        }
      >
        <View
          width="100%"
          display="flex"
          flexDirection="row"
          style={{ justifyContent: 'space-between' }}
          backgroundColor={
            activeMenu?.moduleId === menu.moduleId && !activeSubMenu && !props.hideSelection ? 'primary70' : ''
          }
        >
          <View display="flex" alignSelf="center" width="100%">
            <Text
              fontSize="T200"
              fontWeight="medium"
              color={
                activeMenu?.moduleId === menu.moduleId && !activeSubMenu && !props.hideSelection
                  ? 'neutral0'
                  : 'neutral90'
              }
              style={{ padding: '1rem 2.5vw', display: 'flex' }}
            >
              {menu.moduleName}
            </Text>
          </View>
          <View display="flex" alignSelf="center" padding="0 1.2rem">
            {getModuleIcon(menu.status)}
          </View>
        </View>
        {menu.contents && !isFeedback && activeMenu && activeMenu.moduleId === menu.moduleId && (
          <View>
            {menu.contents.map((content: IContent, index: number) => {
              return getContendItem(content, index);
            })}
          </View>
        )}
      </View>
    );
  };

  const getContendItem = (menu: IContent, index: number): ReactElement => {
    return (
      <View key={index} backgroundColor="neutral05">
        <View
          width="100%"
          display="flex"
          flexDirection="row"
          style={{ justifyContent: 'space-between' }}
          backgroundColor={activeSubMenu?.contentId === menu.contentId ? 'primary70' : ''}
        >
          <View display="flex" alignSelf="center" width="100%">
            <Text
              fontSize="T200"
              color={activeSubMenu?.contentId === menu.contentId ? 'neutral0' : 'neutral90'}
              style={{ padding: '1rem 2.5vw', display: 'flex' }}
            >
              {/* TODO: Consider how to handle RTL & different number systems */}
              {menu.contentName}
              {' ' + (index + 1)}
            </Text>
          </View>
          <View display="flex" alignSelf="center" padding="0 1.2rem">
            {getModuleIcon(menu.status)}
          </View>
        </View>
      </View>
    );
  };

  return (
    <Col
      width="100%"
      style={{
        zIndex: 4,
        height: props.isMobile ? 'min-content' : 'auto',
        boxShadow: props.isMobile ? '0px 15px 10px -15px #111' : '',
        minHeight: props.isMobile ? '' : '95.35%',
        borderRight: props.isMobile ? 'none' : '1px solid #8B96A3',
        background: props.isMobile ? '#ffff' : 'rgb(255 255 255 / 25%)',
      }}
    >
      {props.modules &&
        props.modules.map((menu: IModule, index: number) => {
          return getMenuItem(menu, index);
        })}
    </Col>
  );
};

export default SideNavBar;
