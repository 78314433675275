import { Text } from '@amzn/stencil-react-components/text';
import {
  PageHeader,
  PageHeaderDropdown,
  PageHeaderDropdownButton,
  PageHeaderLink,
} from '@amzn/stencil-react-components/page';
import { Spacer } from '@amzn/stencil-react-components/layout';
import { IconGlobe } from '@amzn/stencil-react-components/icons';
import { useBreakpoints } from '@amzn/stencil-react-components/responsive';
import { LANGUAGES, LANGUAGES_BY_COUNTRY } from '../../Utils/CountryConstant';
import useArb, { Locale } from '../../hooks/useArb';
import { useSessionStorage } from '../../hooks/useSessionStorage';

export default function HeaderTab(props: any) {
  const { t, locale, setLocale } = useArb('appStrings');
  const [countryCode] = useSessionStorage('countryCode', 'other', {parseJSON: false});
  const languages = LANGUAGES_BY_COUNTRY[countryCode as keyof typeof LANGUAGES_BY_COUNTRY];
  const { matches: deviceResolution } = useBreakpoints();

  return (
    <PageHeader isFixed={true} isFullWidth={true} hasShadow={true}>
      <nav aria-label="Site" style={{ display: 'flex', flex: deviceResolution.m || deviceResolution.s ? 0.6 : 0.3 }}>
        <PageHeaderLink href="#" style={{ padding: '0' }}>
          <Text fontSize={'T400'}>{t('PHO-application-title', 'Pre-hire orientation')}</Text>
        </PageHeaderLink>
      </nav>
      <Spacer flex={deviceResolution.m || deviceResolution.s ? 0.1 : 0.6} />
      <nav aria-label="Site" style={{ display: 'flex', flex: deviceResolution.m || deviceResolution.s ? 0.3 : 0.1 }}>
        <PageHeaderDropdown
          icon={<IconGlobe title="Change Language" />}
          items={languages.map((item) => (
            <PageHeaderDropdownButton
              onClick={() => setLocale(item?.shortName as Locale)}
            >
              {item?.longName}
            </PageHeaderDropdownButton>
          ))}
        >
          {LANGUAGES[locale]?.longName}
        </PageHeaderDropdown>
      </nav>
    </PageHeader>
  );
}
