import { useCallback, useMemo } from 'react';
import { useBundle, useLocalizationContext } from '@amzn/react-arb-tools';
import appStrings from '../i18n/appStrings/appStrings.puff.json';
import { useSessionStorage } from './useSessionStorage';
import { localizationContextBuilder } from '../i18n/index';
import { endpointMap } from '../i18n/arbManifest';

export type BundleName = 'appStrings';
export type ResourceKey = keyof typeof appStrings.resources;
export type Locale = keyof typeof endpointMap;

export interface ArbBundle {
  t: (id: ResourceKey, defaultValue?: string, params?: Record<string, any>) => string;
  locale: Locale;
  setLocale: (locale: Locale) => void;
}

/**
 * A wrapper around the useBundle hook from react-arb-tools.
 *
 * Handles unloaded behavior by returning empty string rather than the message.
 * Logs errors in getting translations to console, then returns the key to
 * prevent the entire application from failing if a key isn't found.
 *
 * @param bundleName Name of the ARB Bundle to be used.
 * @returns object with getMessage and formatMessage functions, using the same
 * 	signatures as useBundle.
 */
export default function useArb(bundleName: BundleName): ArbBundle {
  const [countryCode] = useSessionStorage('countryCode', 'other', { parseJSON: false });
  const [bundle, isLoading] = useBundle(bundleName);
  const t = useCallback(
    (id: ResourceKey, defaultValue?: string, params?: Record<string, any>) => {
      if (isLoading) {
        return defaultValue ?? id;
      }

      try {
        return bundle.formatMessage(id, { country: countryCode, ...params });
      } catch (e) {
        console.error(e);
        return defaultValue ?? id;
      }
    },
    [bundle, isLoading, countryCode]
  );

  const { localizationContext, setLocalizationContext } = useLocalizationContext();
  const locale = useMemo(() => localizationContext.getLocale() as Locale, [localizationContext]);
  const setLocale = useCallback(
    (locale = 'en-US') => {
      setLocalizationContext(localizationContextBuilder.withLocale(locale).build());
    },
    [setLocalizationContext]
  );

  return {
    t,
    locale,
    setLocale,
  };
}
