import { LocalizationContextBuilder} from '@amzn/arb-tools';
import arbManifest from './arbManifest';

const DEFAULT_LOCALE = 'en-US';
const INITIAL_LOCALE = (new URLSearchParams(window.location.search).get('locale') || DEFAULT_LOCALE).replace('_', '-');

export const localizationContextBuilder = new LocalizationContextBuilder().withDefaultLocale(DEFAULT_LOCALE);

export const mbmOptions = {
  arbManifest,
  defaultLocalizationContext: localizationContextBuilder.withLocale(INITIAL_LOCALE).build(),
  resolveBundleUrl: (url?: string) => (url ? `/translations/${url}` : undefined),
};
