import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { Container } from '@amzn/stencil-react-components/layout';
import { View } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { Col } from '@amzn/stencil-react-components/layout';
import { IconAlert } from '@amzn/stencil-react-components/icons';
import useArb from '../../hooks/useArb';

interface IPlayerScreenProps {
  altText: string;
  path: string;
}

export default function PlayerScreen(props: IPlayerScreenProps) {
  const { t } = useArb('appStrings');

  const mediaUrl = `${sessionStorage.getItem('cloudfrontS3Distribution')}/${props.path}`;
  const [isPlayable, setIsPlayable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsPlayable(ReactPlayer.canPlay(mediaUrl));
    setIsLoading(!ReactPlayer.canPlay(mediaUrl));
  }, [mediaUrl]);

  return (
    <Container alignItems="center" style={{ marginTop: '10px' }}>
      {isLoading && (
        <Col width="100%" height="100%">
          <Spinner size={SpinnerSize.Small} />
        </Col>
      )}
      {isPlayable ? (
        <ReactPlayer
          muted={false}
          playing
          controls
          style={isLoading ? { display: 'none' } : { display: 'block' }}
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload',
              },
            },
          }}
          onReady={() => {
            setIsPlayable(true);
            setIsLoading(false);
          }}
          onError={(error) => {
            setIsLoading(false);
            setIsPlayable(false);
          }}
          url={mediaUrl}
          width="100%"
          height="100%"
        />
      ) : (
        <View width="100%" height="100%">
          <Text fontSize="T200" color="neutral70" textAlign="left">
            <IconAlert display="inline" />
            <em>
              {' '}
              {t('PHO-error-video-playback', 'Unable to stream video content')}: {props.altText}
            </em>
          </Text>
        </View>
      )}
    </Container>
  );
}
