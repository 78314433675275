import React, { useState, useRef } from "react";
import { MessageBanner, MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import { useBreakpoints } from "@amzn/stencil-react-components/responsive";
import { Col, View } from "@amzn/stencil-react-components/layout";

export const withAlertSnackBar = WrappedComponent => {

  return props => {
    const childFunc = useRef(null)
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("I'm a custom snackbar");
    const [duration, setDuration] = useState(2000);
    const [severity, setSeverity] = useState(MessageBannerType.Success); /** error | warning | info */
    const showMessage = (message, severity = 'error', duration = 2000) => {
      setMessage(message);
      setSeverity(severity);
      setDuration(duration);
      setOpen(true);
    };

    const propsMap1 = {
      s: { width: "80%" },
      m: { width: "80%" },
      l: { width: "60%" },
      xl: { width: "60%" }
    }

    const { props: widthBreakPoints } = useBreakpoints({ propsMap: propsMap1 })

    return (
      <>
        <WrappedComponent {...props} snackbarShowMessage={showMessage} childFunc={childFunc} />
        {open && (
          <Col>
            <View id="alertID" {...widthBreakPoints} alignSelf="center" style={{ position: "fixed", top: "4rem", left: 'auto', zIndex: '999999' }}>
              <MessageBanner
                isDismissible
                autoDismissAfter={duration}
                onDismissed={() => {
                  setOpen(!open);
                }}
                type={severity}
              >
                <>{message}</>
              </MessageBanner>
            </View>
          </Col>
        )}
      </>
    );
  };
};
