import React from 'react';
import { Container, Flex, View } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import Tick from './../../assets/Tick_Outlines.svg';
import bgv from './../../assets/FotterImg2.svg';
import useArb from '../../hooks/useArb';

const ConfirmComponent = (): React.ReactElement => {
  const { t } = useArb('appStrings');
  return (
    <Container isFullWidth={true} data-testid="data-confirm">
      <Flex flexDirection="column" width={'100%'} alignItems="center" padding={'2rem'} style={{ paddingTop: '5rem' }}>
        <View>
          <img src={Tick} alt="" />
        </View>
        <View style={{ marginTop: '16px' }}>
          <Text color="neutral90" fontSize={'T500'} textAlign="center" fontWeight={500}>
            {t('PHO-completion-acknowledgement', 'Thanks for completing your pre-hire orientation')}
          </Text>
        </View>
        <View style={{ marginTop: '24px' }}>
          <Text fontSize={'T300'} textAlign="center">
            {t(
              'PHO-completion-instructions',
              "You'll be contacted by your recruiter for next steps. You're now safe to close this window."
            )}
          </Text>
        </View>
      </Flex>
      <img
        src={bgv}
        width="100%"
        alt={'footerImg'}
        style={{ zIndex: 3, position: 'fixed', display: 'block', bottom: 0 }}
      />
    </Container>
  );
};

export default ConfirmComponent;
