import { useCallback, useEffect } from 'react';
import { useSyncExternalStore } from 'use-sync-external-store/shim';

interface SessionStorageOptions {
  parseJSON?: boolean; // Whether values should be parsed as JSON. If false, string literals are used.
}

/**
 * Syncs state with sessionStorage, providing real-time updates across tabs
 * @param key - Key used to access session storage value
 * @param initialValue - Initial value to use if no value in session storage with key
 * @returns [state, setState] - Returns the current state and a function to update the state
 */
export function useSessionStorage(
  key: string,
  initialValue: any,
  options: SessionStorageOptions = { parseJSON: true }
) {
  const getSnapshot = () => window.sessionStorage.getItem(key);

  // Subscribe to changes in sessionStorage
  const store = useSyncExternalStore(useSessionStorageSubscribe, getSnapshot, getSessionStorageServerSnapshot);

  // Function to update the state in sessionStorage
  const setState = useCallback(
    (v) => {
      try {
        // If v is a fn, call it with current state to get the next state
        const nextState = typeof v === 'function' ? v(JSON.parse(store as string)) : v;

        if (nextState === undefined || nextState === null) {
          removeSessionStorageItem(key);
        } else {
          setSessionStorageItem(key, nextState);
        }
      } catch (e) {
        console.warn(e);
      }
    },
    [key, store]
  );

  // Sets initial value in sessionStorage, if it doesn't exist
  useEffect(() => {
    if (getSessionStorageItem(key) === null && typeof initialValue !== 'undefined') {
      setSessionStorageItem(key, initialValue);
    }
  }, [key, initialValue]);

  return [store ? (options.parseJSON ? JSON.parse(store) : store) : initialValue, setState];
}

/**
 * Dispatches custom event to notify other tabs/windows of changes
 */
function dispatchStorageEvent(key: string, newValue: string | null) {
  window.dispatchEvent(new StorageEvent('storage', { key, newValue }));
}

/**
 * Subscribes to storage events for real-time updates across tabs/windows
 */
const useSessionStorageSubscribe = (callback: (this: Window, ev: StorageEvent) => any) => {
  window.addEventListener('storage', callback);
  return () => window.removeEventListener('storage', callback);
};

/**
 * Throws an error if attempting to use sessionStorage on the server
 */
const getSessionStorageServerSnapshot = () => {
  throw Error('useSessionStorage is a client-only hook');
};

/**
 * Retrieves an item from sessionStorage
 */
const getSessionStorageItem = (key: string) => {
  return window.sessionStorage.getItem(key);
};

/**
 * Sets an item in sessionStorage and dispatches a storage event
 */
const setSessionStorageItem = (key: string, value: any) => {
  const stringifiedValue = JSON.stringify(value);
  window.sessionStorage.setItem(key, stringifiedValue);
  dispatchStorageEvent(key, stringifiedValue);
};

/**
 * Removes an item from sessionStorage and dispatches a storage event
 */
const removeSessionStorageItem = (key: string) => {
  window.sessionStorage.removeItem(key);
  dispatchStorageEvent(key, null);
};
