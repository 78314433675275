import { useState, useEffect } from 'react';
import HeaderTab from './Pages/CommonComponents/HeaderTab';
import HomePage from './Pages/HomePage/HomePage';
import { init } from './api/axios';
import { CandidateService } from './Services/CandidateService';
import FullPageCenteredSpinner from './Pages/CommonComponents/FullPageCenteredSpinner';
import { withAlertSnackBar } from './Components/MessageBanner/AlertSnackBarHOC';
import constants from './Utils/Constants';
import { getProperName } from './Utils/Util';
import { View } from '@amzn/stencil-react-components/layout';
import { PhoService } from './Services/PhoServices';
import {
  getCandidatePortalUrl,
  getGrievanceChatbotUrl,
  isMENACountry,
} from './Utils/CountryConstant';
import { MessageBanner, MessageBannerLink } from '@amzn/stencil-react-components/message-banner';
import { IconExternalLink, IconSize } from '@amzn/stencil-react-components/icons';
import { useLocalStorage } from './hooks/useLocalStorage';
import useArb from './hooks/useArb';
import LanguageModal from './Components/Modals/LanguageModal';

function App(props: any) {
  const { t } = useArb('appStrings');
  const [loader, setLoader] = useState(true);
  const [isOpenModal, setIsOpenModel] = useState(true);
  const [showModules, setShowModules] = useState(false);

  const grievanceChatbotUrl = getGrievanceChatbotUrl();

  const [grievanceFeatureFlag, setGrievanceFeatureFlag] = useLocalStorage(
    constants.FEATURE_FLAGS.GRIEVANCE_REPORTING,
    false
  );

  //call vendor portal proxy to authenticate the user & if fails then redirect it to candidatePortal!
  useEffect(() => {
    const pageUrl = new URL(document.URL);
    const paths = pageUrl.pathname;
    const searchParams = new URLSearchParams(window.location.search);
    if (sessionStorage.getItem('idtoken') === 'null' || sessionStorage.getItem('accesstoken') === null) {
      sessionStorage.setItem('site', paths.split('/')[1]);
      sessionStorage.setItem('role', paths.split('/')[2]);
      sessionStorage.setItem('idtoken', searchParams.get('idtoken') as string);
      sessionStorage.setItem('accesstoken', searchParams.get('accesstoken') as string);
      sessionStorage.setItem('phoId', searchParams.get('phoId') as string);
      sessionStorage.setItem('candidateId', searchParams.get('candidateId') as string);
      sessionStorage.setItem('applicationId', searchParams.get('applicationId') as string);
      reportUsername(searchParams.get('candidateId') as string);
    }

    async function fetchData() {
      setLoader(true);
      if (init) await init();
      await CandidateService.verifySession()
        .then(async (data) => {
          const session = data.data.session;
          if (!session) redirectToCandidatePortal(sessionStorage.getItem('stage'));
          else {
            await getCandidateProfile({
              email: sessionStorage.getItem('candidateId'),
            });
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          /* istanbul ignore next */
          window.setTimeout(() => redirectToCandidatePortal(sessionStorage.getItem('stage')), 1500);
        });
    }
    fetchData();
    // eslint-disable-next-line
  }, []);

  const getAppConfigData = async () => {
    PhoService.getAppConfig()
      .then((res) => {
        setGrievanceFeatureFlag(!!res?.data?.FEATURE_FLAG_ENABLE_GRIEVANCE_REPORTING);
      })
      .catch((err) => {
        /* istanbul ignore next */
        props.snackbarShowMessage(t('PHO-error-server-failed', 'Something went wrong.'), 'error', '3000');
      });
  };

  const getCandidateProfile = async (body: { email: string | null }) => {
    setLoader(true);
    CandidateService.getCandidateProfileByEmail(body)
      .then((res) => {
        const body = res && res.data;
        setLoader(false);
        sessionStorage.setItem('candidateName', getProperName(body));
        const searchParams = new URLSearchParams(window.location.search);
        getAppConfigData();
        if (!searchParams.get('locale')) {
          setIsOpenModel(true);
        }
        setShowModules(true);
      })
      .catch((err) => {
        setLoader(false);
        props.snackbarShowMessage(
          t('PHO-error-profile-failed', 'Something went wrong, unable to fetch profile data.'),
          'error',
          '3000'
        );
      });
  };

  const redirectToCandidatePortal = (stage: any) => {
    props.snackbarShowMessage(
      t(
        'PHO-error-authentication-failed',
        'User session is not verified please login in candidate portal & redirect back...'
      ),
      'error'
    );
    window.setTimeout(() => {
      // @ts-ignore
      window.location.href = getCandidatePortalUrl(stage);
      sessionStorage.clear();
    }, 2500);
  };

  if (loader) {
    /* istanbul ignore next */
    return <FullPageCenteredSpinner size="m" message={t('PHO-loading-user-session', 'Checking user session...')} />;
  }

  return (
    <div style={{ height: '100%' }}>
      <View style={{ height: '56px' }}>{showModules && <HeaderTab />}</View>
      {isMENACountry() && grievanceFeatureFlag && showModules && (
        <MessageBanner>
          {t(
            'PHO-grievance',
            'You deserve fair and equal recruitment practices. However, if you experience a violation during the hiring process, report an issue',
            {
              a: (chunk: any) => (
                <MessageBannerLink
                  href={grievanceChatbotUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<IconExternalLink size={IconSize.ExtraSmall} />}
                >
                  {chunk}
                </MessageBannerLink>
              ),
            }
          )}
        </MessageBanner>
      )}
      <View className="content">
        {showModules && <HomePage />}
        <LanguageModal
          isOpen={isOpenModal}
          shouldCloseOnClickOutside={false}
          close={() => {
            setIsOpenModel(false);
          }}
        />
      </View>
    </div>
  );
}
/* istanbul ignore next */
function reportUsername(username: string): void {
  if (username !== null && username !== '') {
    const authenticatedUserEvent = new CustomEvent('cathode-customerId', {
      detail: {
        customerId: username,
      },
    });
    document.dispatchEvent(authenticatedUserEvent);
  }
}

export default withAlertSnackBar(App);
