import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StencilProvider } from '@amzn/stencil-react-components/context';
import { BrowserRouter } from 'react-router-dom';
import { MbmProvider } from '@amzn/react-arb-tools';
import {mbmOptions} from './i18n/index';
import './index.css';

ReactDOM.render(
  <MbmProvider {...mbmOptions}>
    <StencilProvider>
      <BrowserRouter>
        <Suspense fallback="Loading...">
          <App />
        </Suspense>
      </BrowserRouter>
    </StencilProvider>
  </MbmProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
